import {styled} from "@mui/material/styles";
import {Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import BlueImageButtonTablette from "../../images/hexagone-blue-tab56x61.svg";
import BlueImageButtonTabletteDark from "../../images/hexagone-blue-tab56x61-dark.svg";

/**
 * Constantes spécifiques alvéole
 */

/************************ DeV ******************************************************/
export const urlApi = 'https://api-authentication-dev.cristal-hub.fr';
export const domain = '.cristal-hub.fr';
export const urlApiDB = 'https://api-servicebdd-dev.cristal-hub.fr/api/users';
/************************ LOCAL DeV ************************************************/
// export const domain = 'localhost';
// export const urlApi = "https://localhost:8000";
// export const urlApiDB = 'https://localhost:8002/api/users'

export const CristalLogo = styled('img')(() => ({
    marginLeft: '0'
}));


export const CristalContainerHeaderTitle = styled(Paper)(({theme}) => ({
    backgroundColor: 'unset',
    backgroundImage: 'unset',
    boxShadow: 'unset',
    padding: theme.spacing(1),
}));

export const CristalTextMessage = styled(TextField)(({theme}) => ({
    '& label': {
        fontSize: '16px',
        color: theme.palette.mode === 'light' ? '#00508F' : '#ffffff ',
        fontFamily: 'PoppinsRegular',
    },
    '& input': {
        paddingRight: '16px', // Ajustement pour le label
    },
    '& textarea': {
        fontFamily: 'PoppinsRegular',
    },
    '& fieldset': {
        fontSize: '16px',
        borderColor: theme.palette.mode === 'light' ? '#00508F' : '#ffffff',
        borderRadius: '10px',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: '16px'
    },
    marginBottom: '14px',
    minWidth: '350px',
}));

export const CristalLinkBottomButtonLogin = styled(Button)(({theme}) => ({ //Bouton rechercher par personne
    color: theme.palette.getContrastText('#E11279'),
    backgroundColor: '#E11279',
    marginBottom: '0px',
    marginLeft: '0px',
    padding: '12px 20px',
    lineHeight: '1.5',
    textTransform: 'none',
    borderRadius: '50px',
    '&:hover': {
        backgroundColor: '#B40E61',
    },
}));

export const CristalBlueButton = styled(ButtonBase)(({theme}) => ({
    position: 'relative',
    height: '50px',
    width: '50px',
    backgroundImage: `url(${theme.palette.mode === 'light' ? BlueImageButtonTablette : BlueImageButtonTabletteDark})`,
    backgroundSize: '50px 50px',
    marginRight: '14px'
}));
